import { Col, Container, Image, Row } from "react-bootstrap";
import Divider from "./Divider";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useState, useEffect } from "react";
import Utils from "../utils/Utils";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

function HomeAnggota() {
    const [datas, setDatas] = useState([])
    const [state, setState] = useState(false)

    const getData = () => {
        fetch(`${Utils.url}anggota`)
            .then((response) => response.json())
            .then((json) => {
                setDatas(json.data.rows)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    setTimeout(() => {
        setState(true)
    }, 1000);

    return (
        <section className="mt-5 mb-5">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col>
                                    <h3>Anggota</h3>
                                    <Divider width={10} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {
                            state && (
                                // datas.map((item, index) => (
                                //     <div key={index}>{item.lembaga}</div>
                                // ))
                                <OwlCarousel
                                    className='owl-theme'
                                    autoplay
                                    autoplayTimeout={5000}
                                    loop
                                    margin={10}
                                    center
                                    smartSpeed={1000}
                                    responsiveClass
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 5
                                        }
                                    }}>
                                    {
                                        datas.map((item, index) => (
                                            <Link to={item.url} target="_blank" className="item text-center text-decoration-none hvr-grow" key={index}>
                                                <Image className="rounded-circle mx-auto d-block shadow" style={{ height: '100px', width: '100px' }} src={Utils.urlStorage + item.photo_path} alt={item.photo} />
                                                <div className="mt-3" style={{ color: '#464646', fontWeight: '600' }}>{item.lembaga}</div>
                                            </Link>
                                        ))
                                    }
                                </OwlCarousel>
                            )
                        }
                    </Row>
                </Container>
            </ScrollAnimation>
        </section>
    );
}

export default HomeAnggota;