import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import HeadDokumenHukum from "../../../components/HeadDokumenHukum";
import Divider from "../../../components/Divider";
import Utils from "../../../utils/Utils";
import { Link } from "react-router-dom";

function Anggota(props) {

    return (
        <>
            <section className="pb-5" style={{ background: '#dedede' }}>
                <HeadDokumenHukum title="Anggota" />
                <Container>
                    <Row className="mt-5 align-item-center justify-content-center h-100">
                        <Col lg={10} className="text-center">
                            <Card>
                                <Card.Body className="text-start">
                                    <div style={{ fontWeight: '600', color: '#464646' }}>
                                        JDIH Badan Nasional Pengelola Perbatan RI
                                    </div>
                                    <Divider width={20} />
                                    <table className="table table-sm table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center pb-3 pt-3" style={{ width: '10%' }}>No</th>
                                                <th className="pb-3 pt-3">Nama</th>
                                                <th className="text-center pb-3 pt-3" style={{ width: '20%' }}>Logo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.anggota && (
                                                props.anggota.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="text-center" style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <Link to={item.url} className="text-dark text-decoration-none" target="_blank">{item.lembaga}</Link>
                                                        </td>
                                                        <td className="text-center">
                                                            <Image src={`${Utils.urlStorage}${item.photo_path}`} style={{ height: '50px' }} />
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        anggota: state.anggota
    }
}

export default connect(mapStateToProps)(Anggota);